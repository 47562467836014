import { Formik, Field, Form, ErrorMessage, FormikHelpers } from 'formik';
import LoadingSpinner from '../../../LoadingIndicator/LoadingSpinner';
import { StyledFormRow } from '../../../../styles';
import * as Yup from 'yup';
import useAuth from '../../../../helpers/useAuth';
import Button from '../../Button/Button';
import { navigateToApp } from '../../../../helpers/navigationHelpers';

interface MyFormValues {
  email: string;
  password: string;
}

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
});

const LogInForm = () => {
  const { loginUser } = useAuth();

  async function submitUser(values: MyFormValues, helpers: FormikHelpers<MyFormValues>) {
    helpers.setStatus(null);
    const payload = {
      credentials: {
        email: values.email,
        password: values.password,
      },
    };

    try {
      const res = await loginUser(payload);
      if (res.success) {
        navigateToApp();
      } else {
        errorCallback();
      }
    } catch (e) {
      console.warn('Sign in failed.');
    }

    function errorCallback() {
      helpers.setSubmitting(false);
      helpers.setStatus({ message: 'Login information was not corred.' });
    }
  }

  return (
    <Formik initialValues={{ email: '', password: '' }} onSubmit={submitUser} validationSchema={LoginSchema} validateOnBlur={false}>
      {({ errors, status, touched, isSubmitting, isValid, validateField, values: currentValues }) => (
        <Form translate={undefined} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <StyledFormRow>
            <label htmlFor="email">
              <Field
                autoFocus
                autoComplete="username"
                className={'site--form-text-field'}
                data-testid="email"
                placeholder={'Email'}
                type={'email'}
                name={'email'}
              />
            </label>
            <ErrorMessage className="form--error-message" name={'email'} component={'div'} />
          </StyledFormRow>
          <StyledFormRow>
            <label htmlFor="password">
              <Field
                autoComplete="current-password"
                className={'site--form-text-field'}
                data-testid="password"
                placeholder={'Password'}
                type={'password'}
                name={'password'}
              />
            </label>
            <ErrorMessage className={'form--error-message'} name={'password'} component={'div'} />
          </StyledFormRow>
          <div style={{ height: '20px' }}>
            {status && status.message && <div>{status.message}</div>}
            {isSubmitting && <LoadingSpinner kind={'circle'} r={20} />}
          </div>
          <div data-testid="buttonholder" style={{ marginTop: '10px' }}>
            <Button type="submit" disabled={!isValid || isSubmitting || Object.values(currentValues).some((v) => !v)} buttonType={'site'}>
              Log in
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LogInForm;
